<template>
  <section>
    <Spin v-if="busy" class="loading" />
    <!-- BEGIN panel editor toolbar -->
    <div v-if="showCustomToolbar" class="panel-toolbar no-print">
      <slot name="toolbar"></slot>
    </div>
    <!-- END panel editor toolbar -->
    <!-- BEGIN table rows per page configuration -->
    <div class="html2canvas-ignore table-config" data-html2canvas-ignore>
      <DownloadButton
        v-if="
          !printPreview &&
          mode != 'editor' &&
          tableId &&
          (panel.options.downloadXLS || panel.options.downloadCSV)
        "
        @ready="onDownloading(true)"
        @done="onDownloading(false)"
        :tableId="tableId"
        :fixRight="true"
        :downloading="downloading"
        :xls="panel.options.downloadXLS"
        :csv="panel.options.downloadCSV"
      />
      <template v-if="printPreview">
        <i
          class="fa fa-pencil clicable"
          v-if="!pageEditor"
          :title="$t('titles.items_per_page')"
          @click.stop.prevent="pageEditor = true"
        ></i>
        <div v-if="pageEditor">
          <input
            type="number"
            ref="nItemsInFirstPage"
            class="form-control text-center"
            placeholder="auto"
            v-model="nItemsInFirstPage"
          />
          <input
            type="number"
            class="form-control text-center"
            placeholder="auto"
            v-model="nItemsInRemainingPages"
          />
          <i
            class="fa fa-undo clicable"
            @click.stop.prevent="pageEditor = false"
          />
          <span class="text-bold">1</span>
          <span class="fa fa-asterisk"></span>
        </div>
      </template>
    </div>
    <!-- END table rows per page configuration -->
    <div class="display">
      <div
        v-if="panel.options.title.text"
        :style="panel.options.title.style"
        @click.stop.prevent="onTitleClicked"
        ref="title"
      >
        {{ titleValue }}
      </div>
      <div
        v-if="panel.options.subTitle.text"
        :style="panel.options.subTitle.style"
        @click.stop.prevent="onSubTitleClicked"
        ref="subtitle"
      >
        {{ subTitleValue }}
      </div>
      <SynopticSimpleTable
        v-if="ready"
        ref="table"
        @tableId="tableId = $event"
        :control="control"
        :standAlone="false"
        :offsetTop="tableOffset"
        :inlineEditor="inlineEditor"
        :datasetIdList="datasetIdList"
        :dataTable="dataTable"
        :nItemsInFirstPage="nItemsInFirstPage"
        :nItemsInRemainingPages="nItemsInRemainingPages"
        :namedQuery="namedQuery"
        @tableEvent="trigger($event)"
      />
    </div>
  </section>
</template>

<script>
import SynopticSimpleTable from "@/components/synoptic/synoptic-simple-table.vue";
import Spin from "@/components/spin.vue";
import TableForm from "@/components/control-sidebar/property-editors/table-form.vue";
import DownloadButton from "@/components/download-button.vue";
import {uniqBy, sortBy, debounce} from "lodash";
import {isSyncEnabled} from "@/services/dashboard.js";
import {fillList, linearInterpolation} from "@/modules/history.js";
import {defCell} from "@/components/control-sidebar/property-editors/detail-form-table.vue";
export default {
  name: "DashboardTablePanel",
  props: {
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  inject: {
    pageSettings: {
      from: "pageSettings",
      default: null
    }
  },
  components: {
    SynopticSimpleTable,
    Spin,
    DownloadButton
  },
  data() {
    return {
      tableOffset: 0,
      ready: false,
      pageConfiguration: {
        first: undefined,
        remaining: undefined,
        editing: false
      },
      downloading: false,
      tableId: "",
      dataTable: null
    };
  },
  computed: {
    printScale() {
      return this?.pageSettings?.scale ?? 1;
    },
    control() {
      return {
        synopticComponent: this?.panel?.options
      };
    },
    historyDataIdList() {
      let lst = [...this.datasetIdList];
      let sheet = this.panel.options.sheet;
      for (var c in sheet) {
        for (var r in sheet[c]) {
          if (
            sheet[c][r].data_id &&
            sheet[c][r].value.indexOf(".history.") != -1
          ) {
            lst.push(sheet[c][r].data_id);
          }
        }
      }
      return this.$utils.distinct(lst);
    },
    historyInterval() {
      return (
        (this.historyDataIdList.length &&
          this.$store.getters["history/interval"]) ||
        null
      );
    },
    busy() {
      return (
        (this.$store.getters[`${this.namedQuery || "history"}/pending`] || [])
          .length > 0
      );
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    showCustomToolbar() {
      return (this?.panel?.toolbar || []).length > 0;
    },
    printPreview() {
      return this?.$store?.getters?.print || false;
    },
    titleValue() {
      // example1: `Connector: ${$('4584').name}`
      // example2: `Connector: ${$('.').name}`
      let exp = this?.panel?.options?.title?.text || "";
      if (!exp) return exp;
      if (exp == "title") {
        return this.$t(exp);
      }
      let ret = this.$root.$formatter.format({template: exp});
      return ret === "" ? exp : ret;
    },
    subTitleValue() {
      // example1: `Device: ${$('4584/4328').name}`
      // example2: `Device: ${$('./4328').name}`
      let exp = this?.panel?.options?.subTitle?.text || "";
      if (!exp) return exp;
      if (exp == "subtitle") {
        return this.$t(exp);
      }
      let ret = this.$root.$formatter.format({template: exp});
      return ret === "" ? exp : ret;
    },
    nItemsInFirstPage: {
      set(value) {
        this._set("first", value);
      },
      get() {
        return this.pageConfiguration.first || undefined;
      }
    },
    nItemsInRemainingPages: {
      set(value) {
        this._set("remaining", value);
      },
      get() {
        return this.pageConfiguration.remaining || undefined;
      }
    },
    pageEditor: {
      set(value) {
        this.pageConfiguration.editing = value;
        if (value) {
          this.$nextTick(() => {
            this.$refs.nItemsInFirstPage.focus();
          });
        } else {
          this.pageConfiguration.first = undefined;
          this.pageConfiguration.remaining = undefined;
          this.pageConfiguration.editing = false;
        }
      },
      get() {
        return this.pageConfiguration.editing || false;
      }
    },
    inlineEditor() {
      return this.isEditing && !isSyncEnabled(this.display, this.panel.name);
    },
    namedQuery() {
      return this?.panel?.options?.namedQuery || "";
    },
    limit() {
      let value = this?.panel?.options?.dataSetConfig?.limit || 0;
      return Math.abs(isNaN(Number(value)) ? 0 : parseInt(value)) || "";
    },
    offset() {
      let value = this?.panel?.options?.dataSetConfig?.offset || 0;
      return Math.abs(isNaN(Number(value)) ? 0 : parseInt(value)) || "";
    },
    datasetColumns() {
      return (this?.panel?.options?.dataSetConfig?.dataList || []).filter(
        ({enabled}) => enabled ?? true
      );
    },
    datasetIdList() {
      return this.$utils.distinct(
        this.datasetColumns.map(({data_id}) => parseInt(data_id))
      );
    },
    datasetDataList() {
      return (this.$store.getters["dashboard/dataList"] || []).filter(
        ({id}) => this.datasetIdList.indexOf(parseInt(id)) >= 0
      );
    },
    history() {
      if (!this?.datasetDataList?.length) return null;
      let entries =
        this.$store.getters[
          this.namedQuery
            ? `${this.namedQuery}/aggregatedEntries`
            : "history/entries"
        ] || {};
      if (this.namedQuery) return entries;
      let ret = {};
      this.datasetIdList.forEach((id) => {
        if (entries[id]) ret[id] = entries[id];
      });
      return ret;
    },
    datasetTarget() {
      // convert A1 notation to {c:0, r:0} (target cell)
      let target = null;
      if (this?.control?.synopticComponent?.sheet) {
        let cfg = this?.control?.synopticComponent?.dataSetConfig || {};
        if (cfg?.address) {
          let c = parseInt(cfg.address.charCodeAt(0) - 65);
          let r = parseInt(cfg.address.replace(/\D/g, "")) - 1;
          if (
            r >= 0 &&
            r <= this?.control?.synopticComponent?.sheet.length - 1 &&
            c >= 0 &&
            c <= this?.control?.synopticComponent?.sheet[0].length - 1
          ) {
            target = {r: r, c: c};
          }
        }
      }
      return target;
    },
    dataset() {
      // !mportant:
      // dataId might repeat in diffent columns, therefor do not replace by the computed property
      const offset = this.offset || 0;
      const limit = this.limit || 0;
      const history = {};
      const datasetColumnIdList = this.datasetColumns.map(
        ({data_id, index}, ix) => {
          if (this.namedQuery) {
            history[ix] = this.history[index]; // original position since column might be disabled
            return ix;
          } else {
            history[data_id] = this.history[data_id];
            return parseInt(data_id);
          }
        }
      );
      if (!history || !datasetColumnIdList.length) return null;
      // figure out the data with larger number of samples
      let keyAttr = this.namedQuery ? "key" : "time";
      let canInfer = true;
      let parsers = {};
      this.datasetDataList.forEach(({id, type}) => {
        parsers[id] =
          type == "string" ? null : type == "bool" ? parseInt : parseFloat;
        if (canInfer && (type == "string" || type == "bool")) {
          canInfer = false;
        }
      });
      // possible injected columns [seq, time, data1-n...]
      let missingValues =
        (canInfer &&
          (this?.control?.synopticComponent?.dataSetConfig || {})
            .missingValues) ||
        "last_value";

      let sequenceColumn = (
        this?.control?.synopticComponent?.dataSetConfig || {}
      ).sequenceColumn
        ? true
        : false;
      let timeColumn = (this?.control?.synopticComponent?.dataSetConfig || {})
        .timeColumn
        ? true
        : false;
      let cols = (
        sequenceColumn && timeColumn
          ? ["seq", "timestamp"] // sequence and time
          : sequenceColumn || timeColumn
          ? ["timestamp"] // sequence or time
          : []
      ).concat(datasetColumnIdList);

      // reference column
      let refColId = null;
      let refCol = null;
      // IMPORTANT
      // this configuration is not yet available for user configuration (panel form)
      // In order to have compatible results with HistoryPanel, the new "leave_them_empty" value
      // has been introduced. Right now it forces cfgRefCol type to "None"

      let cfgRefCol = (this?.control?.synopticComponent?.dataSetConfig || {})
        ?.refColumn || {
        type: "auto",
        data_id: null
      };
      // TODO: as stated by previous comment
      // remove line below as soon reference column be available for user configuration
      if (missingValues == "leave_them_empty") {
        cfgRefCol.type = "none";
      }

      // build reference column
      if (cfgRefCol.type == "auto") {
        var ta, tb, ca, cb;
        refColId = (datasetColumnIdList || []).reduce((a, b) => {
          ca = a in history ? history[a]?.stats?.count || 0 : 0;
          cb = b in history ? history[b]?.stats?.count || 0 : 0;
          ta = ((history[a]?.samples || [])[0] || {})[keyAttr] || 0;
          tb = ((history[b]?.samples || [])[0] || {})[keyAttr] || 0;
          // max number of samples or last first sample
          return !ca && !cb ? -1 : ca > cb ? a : cb > ca ? b : ta >= tb ? a : b;
        });
        refColId = refColId == -1 ? datasetColumnIdList[0] : refColId;
        refCol = history[refColId]?.samples;
      } else if (cfgRefCol.type == "none") {
        refCol = [];
        for (var dataId in history) {
          refCol = refCol.concat(
            (history[dataId]?.samples || []).map((i) => ({
              time: i[keyAttr]
            }))
          );
        }
        refCol = sortBy(uniqBy(refCol, keyAttr), keyAttr);
      } else {
        // It get refColId from panel configuration or just the first one
        refColId =
          cfgRefCol.data_id && cfgRefCol.data_id in history
            ? cfgRefCol.data_id
            : datasetColumnIdList[0];
        refCol = history[refColId]?.samples;
      }
      if (!refCol) {
        console.log("invalid configuration");
        return null;
      }

      const array = (lst, attr) => {
        return (lst || []).map((item) =>
          item.data_id &&
          item[attr] !== "" &&
          attr == "value" &&
          parsers[item.data_id]
            ? parsers[item.data_id](item[attr])
            : item[attr]
        );
      };

      let x = array(refCol, keyAttr);
      cols = cols.map((id) => {
        if (sequenceColumn) {
          sequenceColumn = false; // turn off the sequence column
          return Array.from({length: x.length}, (_, i) => i + 1);
        }
        if (timeColumn) {
          timeColumn = false; // turn off the time column
          return x;
        }
        if (refColId && refColId == id) {
          return array(refCol, "value");
        } else {
          let lst = history[id]?.samples || [];
          if (!lst.length) return [];
          switch (missingValues) {
            case "last_value":
              return array(fillList(refCol, lst, false), "value");
            case "leave_them_empty":
              return array(fillList(refCol, lst, true), "value");
            case "linear_interpolation":
              return linearInterpolation(
                x,
                array(lst, keyAttr),
                array(lst, "value")
              );
            // any other function...
          }
        }
      });
      // begin SLICE it if needed
      if (offset < refCol.length) {
        for (var ic = 0; ic < cols.length; ic++) {
          if (offset) {
            cols[ic] =
              limit && offset + limit < cols[ic].length
                ? cols[ic].slice(offset, offset + limit)
                : cols[ic].slice(offset);
          } else if (limit && limit < cols[ic].length) {
            cols[ic] = cols[ic].slice(0, limit);
          }
        }
      }
      // end SLICE
      return cols;
    },
    pendingIds() {
      return (
        (this.datasetIdList.length &&
          this.$store.getters[`${this.namedQuery || "history"}/pending`]) ||
        []
      );
    },
    localFetch() {
      return (
        this.mode == "viewer" &&
        !this.namedQuery &&
        this.datasetColumns.length &&
        this.datasetTarget
      );
    }
  },
  watch: {
    historyInterval: {
      handler() {
        this.fetch();
      },
      deep: true,
      immediate: true
    },
    isEditing: {
      handler(n) {
        if (n) {
          this.setEditingStyle();
          if (this.sidebar.name != "TableForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: TableForm
            });
            // this.$nextTick(() => {
            //   this.setSideBar();
            // });
          }
        }
      },
      immediate: true
    },
    sidebar: {
      handler(n) {
        if (this.isEditing && n && n.name == "TableForm") {
          this.trigger({
            action: "sheet:activate"
          });
        }
      },
      deep: true
    },
    busy(n, o) {
      if (o && !n) {
        this.$forceUpdate();
      }
    },
    pendingIds(n, o) {
      if (o?.length && !n?.length) {
        this.validateDataSet();
      }
    }
  },
  methods: {
    fetch() {
      // at editor mode, a simulation will be made. NamedQueries are globally fetched
      if (!this.localFetch) return;
      this.$store.dispatch("history/fetch", this.historyDataIdList);
    },
    onTitleClicked() {
      this.trigger({
        action: "title:activate"
      });
    },
    onSubTitleClicked() {
      this.trigger({
        action: "sub_title:activate"
      });
    },
    onTableEvent(ev) {
      this.trigger(ev);
    },
    trigger(ev) {
      this.setSideBar();
      this.$nextTick(() => {
        ev.details = ev.details || {};
        if (!ev.details.panelName) {
          ev.details.panelName = this.panel.name || "";
        }
        if (!ev.details.control) {
          ev.details.control = this.control.synopticComponent;
        }
        this.$root.$emit("table:event", ev);
      });
    },
    setSideBar() {
      if (
        (this?.sidebar?.name || "") != "TableForm" &&
        (this?.sidebar?.contentPanelWidget || "") != "DetailFormTable" &&
        this.mode == "editor"
      ) {
        this.$root.$emit("controlSidebar:setContent", TableForm);
      }
    },
    setEditingStyle() {
      if (this.$el) {
        this.$el.style["z-index"] = "1";
        //this.$el.style["overflow"] = "unset";  // without it cell menu are cliped
      }
    },
    setupPrint(status) {
      if (
        this.printPreview &&
        this.printScale &&
        this?.panel?.options?.dataSetConfig?.address &&
        !this.tableOffset
      ) {
        // find any element that will not appear in this page and that is above this panel (maz)
        let panelOffsetTop = this.$el.offsetTop;
        // let hiddenPanelsHeights = 0;
        // this.$el
        //   .closest(".paper")
        //   ?.getElementsByClassName("__equipment_toolbar_panel__")
        //   .forEach((e) => {
        //     hiddenPanelsHeights +=
        //       e?.offsetTop < panelOffsetTop ? e?.clientHeight ?? 0 : 0;
        //   });
        let hiddenPanelsHeights = 44;
        let titleHeight = this.$refs.title?.clientHeight ?? 0;
        let subtitleHeight = this.$refs.subtitle?.clientHeight ?? 0;
        this.tableOffset =
          (panelOffsetTop +
            titleHeight +
            subtitleHeight -
            hiddenPanelsHeights) *
          (this.printScale ?? 1);
        // console.log(`${this.panel.options.title.text} ${this.tableOffset}`);
        return;
      }
      this.tableOffset = 0;
    },
    onDownloading(opt) {
      this.downloading = opt;
      this.topMost();
    },
    topMost() {
      setTimeout(
        () => {
          this.$el.style.overflow = "visible";
          // this.$el.style["min-height"] = "auto";
        },
        100,
        this
      );
    },
    validateDataSet() {
      if (!this?.datasetIdList?.length || this?.pendingIds?.length) return;
      const status =
        this.$store.getters[`${this.namedQuery || "history"}/ready`] || {};
      if (this.datasetIdList.some((id) => status[id])) {
        this.buildValues();
      } else {
        this.dataTable = null;
      }
    },
    async buildValues() {
      // keep in mind this?.control?.synopticComponent? will be soon synopticComponent properties
      // console.time(`buildValues${this._uid}`);
      this.dataTable = null;
      let values = this?.control?.synopticComponent?.sheet || null;
      if (!values || !values.length || !values[0].length) {
        // console.timeEnd(`buildValues${this._uid}`);
        return;
      }
      const nColumns = values[0].length;

      // dataset configuration
      const target = this.datasetTarget;
      if (!this.dataset || !target) {
        this.dataTable = values;
        // console.timeEnd(`buildValues${this._uid}`);
        return;
      }

      // build a combined values
      values = JSON.parse(JSON.stringify(values));

      // freeze cell references - since it will be merged with this.dataset
      let refRow = values[target.r].map((col) =>
        JSON.parse(JSON.stringify(col))
      );

      // merge this.dataset (array) with values
      let cell = null;
      for (var c = 0; c < nColumns; c++) {
        let hasHistory = false;
        let col = c - target.c;
        if (col >= 0 && col < this.dataset.length) {
          hasHistory = (this.dataset || [])[col] ? true : false;
        }
        for (var r = target.r; r < target.r + this.dataset[0].length; r++) {
          let row = r - target.r;
          cell = defCell();
          cell.value = hasHistory ? this.dataset[col][row] : "";
          cell.style = refRow[c].style;
          cell.format = refRow[c].format;
          cell.default = refRow[c].default;
          values[r] = values[r] || [];
          values[r][c] = cell;
        }
      }
      this.dataTable = values;
    }
  },
  mounted() {
    if (this.isEditing) {
      this.setEditingStyle();
    } else if (this.mode != "editor") {
      if (this.printPreview && this?.panel?.options?.dataSetConfig?.address) {
        // this.$root.$on("dashboard:printing", this.setupPrint);
        this.$nextTick(() => {
          setTimeout(
            () => {
              this.setupPrint();
              this.ready = true;
              // console.log(this.tableOffset);
            },
            1000,
            this
          );
        });
        return;
      }
    }
    this.$nextTick(() => {
      this.ready = true;
      this.validateDataSet();
    });
  },
  created() {
    this._set = debounce((p, v) => {
      if (v) {
        this.pageConfiguration[p] = parseInt(v);
      } else {
        this.pageConfiguration[p] = undefined;
      }
    }, 200);
  }
};
</script>

<style scoped>
.loading {
  position: absolute;
  width: 32px;
  top: 50%;
  left: 50%;
  z-index: 9;
}

.display {
  clear: both;
  width: 100%;
}
.panel-title {
  padding-left: 10px;
}

.panel-toolbar {
  position: absolute;
  top: 3px;
  left: 0px;
  background-color: transparent;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.table-config {
  position: absolute;
  right: 0;
  top: 0;
}

.table-config > div {
  width: 100px;
}

.table-config > i {
  margin-right: 5px;
}

.table-config > div > input {
  width: 50%;
  display: inline-block;
  background-color: transparent;
  padding: 8px 0 0 0;
}

.table-config > div > i.fa-undo {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 9pt;
  z-index: 3;
  color: #666;
}

.table-config > div > span {
  position: absolute;
  top: 0;
  left: 3px;
  z-index: 1;
  font-size: 6pt;
  color: #777;
}

.table-config > div > span:last-child {
  margin-left: 50%;
}
</style>
